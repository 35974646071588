import { components } from '@peachjar/components'
import { AddressAndPlaceId } from '@peachjar/ui/dist/lib/components/Inputs/AddressInput'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { css } from 'react-emotion'
import { connect } from 'react-redux'
import { History, withRouter } from 'react-router-dom'
import { CallToAction as CTA, CallToAction, CallToActionType, Flyer } from '../api/Flyer'
import { getPdfUrlFromResponse } from './utils/utils'
import ErrorScreen from '../_middleware/errors/screens/ErrorFlyerManager'
import { httpClient } from '../_middleware/fetch/http'
import DefaultPage from '../components/DefaultPage'
import TwoColumns from '../components/TwoColumns'
import BasicFlyerInfo from './components/BasicInfo'
import Distributions from './components/Distributions'
import FlyerPreview from './components/FlyerPreview'
import FlyerSummary from './components/FlyerSummary'
import Footer from './components/Footer'
import FeaturedLinkList from './components/FeaturedLinks'
import { FlyerCategoryCode } from './components/FlyerCategoryTag'
import { omit } from 'lodash'

const MAX_FILE_SIZE_IN_MB = 50

const DEFAULT_ERROR_NOTIFY_MSG =
  'Unfortunately, something went wrong and we were unable to update the information. Please try again.'

const DEFAULT_SUCCESS_NOTIFY_MSG = 'Flyer information has been updated.'

const {
  Notifications: { notifySuccess, notifyError },
} = components

type Props = {
  history: History
  match: any
  onSuccess: (msg?: string) => void
  onError: (msg?: string) => void
}

const TEXT_LIMIT = 45
const ERROR_TEXT_LIMIT = 'Enter 45 characters or less.'
const ERROR_MISSED_FIELD = 'You missed this field.'

const FlyerManager: React.FunctionComponent<Props> = ({
  match,
  onSuccess,
  onError,
}) => {
  const [flyer, setFlyer] = useState<Flyer | null>(null)
  const [uploadingFlyer, setUploadingFlyer] = useState(false)
  const [flyerTitleError, setFlyerTitleError] = useState<string | null>(null)
  const [fatalError, setFatalError] = useState(false)
  const [invalidCtas, setInvalidCtas] = useState(false)
  const [editingCtas, setEditingCtas] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const {
    params: { id: flyerId },
  } = match

  const handleFlyerTitleChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value } = event.target

    setFlyer({ ...flyer, title: value } as Flyer)

    if (!value) {
      setFlyerTitleError(ERROR_MISSED_FIELD)
      return
    }

    if (value.length > TEXT_LIMIT) {
      setFlyerTitleError(ERROR_TEXT_LIMIT)
      return
    }

    setFlyerTitleError(null)
  }

  const handleFlyerCategoriesChange = (
    categories: FlyerCategoryCode[]
  ) => {
    setFlyer({ ...flyer, categories } as Flyer)
  }

  const handleFlyerAddressChange = (address: AddressAndPlaceId | null) => {
    setFlyer({ ...flyer, eventLocation: address } as Flyer)
  }

  /** =============== HANDLE EXPIRATION DATE =============== */
  const handleDateChange = (d: any) => {
    setFlyer({ ...flyer, endDate: d } as Flyer)
  }
  const handleTargetDateChange = (d: any) => {
    setFlyer({ ...flyer, startDate: d, endDate: '' } as Flyer)
  }



  async function handleFlyerUpload(file: File) {
    const fileSizeInMB = convertBToMB(file.size)
    const fileExtension = file.name.split('.').pop() || 'pdf'

    if (fileSizeInMB > MAX_FILE_SIZE_IN_MB) {
      onError(
        'Unable to upload flyer. Please select a flyer 50MB in size or smaller.'
      )
      return
    }

    setUploadingFlyer(true)

    try {
      // 1. Get presignedUrl from S3
      const { presignedUrl } = await httpClient.fetchPresignedPdfUrl( fileExtension )
      if (!presignedUrl) {
        throw new Error('Error fetching presigned S3 url')
      }

      // 2. PUT request to S3 bucket using presigned S3 url
      const result = await uploadFlyerToS3(presignedUrl, file)

      // 3. Parse pdfUrl from put response
      const pdfUrl = getPdfUrlFromResponse(result)

      // 4. Attempt to upload flyer
      const {
        flyer: uploadedFlyer, validationErrors,
      } = await httpClient.uploadFlyer(pdfUrl)

      if (!uploadedFlyer || (validationErrors &&
        Array.isArray(validationErrors) &&
        validationErrors.length
      )) {
        throw new Error('Error creating new flyer entity')
      }

      // 5. Set update campaign
      const flyerData = {
        flyerPages: uploadedFlyer.flyerPages.map(page => omit(page, ['__typename'])),
        numberOfPages: uploadedFlyer.numberOfPages,
        pdfUrl: uploadedFlyer.pdfUrl || pdfUrl,
      }
      const response = await httpClient.updateFlyerDetails({
        flyerId: parseInt(flyerId, 10),
        campaignId: flyer!.campaignId,
        ...flyerData,
      })
      setFlyer(flyer => ({
        ...flyer,
        ...flyerData,
      }) as Flyer)

      if (!response.success) {
        throw new Error('Error updating flyer details')
      }

      onSuccess()
      setUploadingFlyer(false)
    } catch (e) {
      onError()
      setUploadingFlyer(false)
    }
  }

  const handleOcrUpdate = (userTexts: string[]) => {
    setFlyer(flyer => {
      if (!flyer) {
        return null
      }
      return {
        ...flyer,
        flyerPages: flyer.flyerPages.map((page, index) => ({
          ...page,
          userText: userTexts[index],
        })),
      }
    })
  }

  const updateFlyerDetails = async () => {
    if (!flyer) return
    try {
      const input = {
        flyerId: parseInt(flyerId, 10),
        campaignId: flyer.campaignId,
        pdfUrl: flyer.pdfUrl,
        startDate: flyer.startDate,
        endDate: flyer.endDate,
        numberOfPages: flyer.numberOfPages,
        flyerPages: flyer.flyerPages.map((f) => ({
          jpegUrl: f.jpegUrl,
          ocrText: f.ocrText,
          userText: f.userText,
          pageNumber: f.pageNumber,
        })),
        eventLocation: !flyer.eventLocation
          ? ''
          : flyer.eventLocation.address,
        ctas: flyer.ctas.map((cta) => omit(cta, ['__typename'])),
        featuredLinkIds: flyer.featuredLinkIds || [],
        title: flyer.title,
        categories: flyer.categories || [],
      }
      const response = await httpClient.updateFlyerDetails(input)

      if (!response.success) {
        onError()
        return
      }

      onSuccess()
    } catch (e) {
      console.log('Error updating flyer details')
      onError()
    }
  }

  useEffect(function fetchFlyerDetails() {
    (async () => {
      try {
        const { success, flyerDetails } = await httpClient.fetchFlyerDetails(
          flyerId
        )

        setLoaded(true)
        if (success && flyerDetails) {
          setFlyer({
            ...flyerDetails,
            eventLocation: flyerDetails.eventLocation
              ? { googlePlaceId: '', address: flyerDetails.eventLocation }
              : null,
          })
          return
        }

        setFatalError(true)
      } catch (e) {
        setLoaded(true)
        setFatalError(true)
      }
    })()
  }, [])

  function setFeaturedLinkType(id: string, type: CallToActionType) {
    if (!flyer) return

    setFlyer({
      ...flyer,
      ctas: flyer.ctas.map((cta) =>
        cta.id === id ? { ...cta, type } : cta
      ),
    })
  }
  function setFeaturedLinkValue(id: string, value: string) {
    if (!flyer) return

    setFlyer({
      ...flyer,
      ctas: flyer.ctas.map((cta) =>
        cta.id === id ? { ...cta, value } : cta
      ),
    })
  }
  function featureNextLink() {
    if (!flyer) return

    const nextCTA = flyer.ctas.find(
      (cta) => {
        if (flyer.featuredLinkIds === null) return true
        return !flyer.featuredLinkIds.some((id) => cta.id === id)
      }
    )

    if (nextCTA) {
      setFlyer({
        ...flyer,
        featuredLinkIds: [...(flyer.featuredLinkIds || []), nextCTA.id],
      })
    }
  }
  function replaceFeaturedLink(id: string, newId: string) {
    if (!flyer) return

    const idIndex = flyer.featuredLinkIds!.findIndex((linkId) => linkId === id)
    if (idIndex === -1) return

    const newFeaturedLinkIds = [...flyer.featuredLinkIds!]
    newFeaturedLinkIds[idIndex] = newId
    setFlyer({
      ...flyer,
      featuredLinkIds: newFeaturedLinkIds,
    })
  }
  function removeFeaturedLink(id: string) {
    if (!flyer) return

    setFlyer({
      ...flyer,
      featuredLinkIds: flyer.featuredLinkIds!.filter((linkId) => linkId !== id),
    })
  }
  function addNewFeaturedLink(type: CallToActionType, value: string) {
    if (!flyer) return

    const newCta: CallToAction = {
      // @ts-ignore
      id: window.crypto.randomUUID(),
      type,
      value
    }
    setFlyer({
      ...flyer,
      ctas: [...flyer.ctas, newCta],
      featuredLinkIds: [...(flyer.featuredLinkIds || []), newCta.id],
    })
  }

  if (!loaded) {
    return null
  }

  if (fatalError || !flyer || !flyerId) {
    return (
      <DefaultPage title="Flyer Manager">
        <ErrorScreen />
      </DefaultPage>
    )
  }

  const pageValid = !flyerTitleError &&
    !(flyer.endDate.length === 0) &&
    !invalidCtas &&
    !editingCtas &&
    (flyer.categories !== null && flyer.categories.length > 0) &&
    !uploadingFlyer

  return (
    <DefaultPage title="Flyer Manager">
      <FlyerSummary flyer={flyer} flyerId={flyerId} />
      <TwoColumns>
        <>
          <div className={styles.section}>
            <BasicFlyerInfo
              flyerTitle={flyer.title}
              flyerAddress={flyer.eventLocation}
              flyerTitleError={flyerTitleError}
              textLimit={TEXT_LIMIT}
              handleFlyerTitleChange={handleFlyerTitleChange}
              handleFlyerAddressChange={handleFlyerAddressChange}
              categorySet={flyer.ownerHierarchyType}
              categories={flyer.categories || []}
              setCategories={handleFlyerCategoriesChange}
            />
          </div>
          <div className={styles.section}>
            <FeaturedLinkList
              allLinks={flyer.ctas}
              featuredLinkIds={flyer.featuredLinkIds || []}
              maxFeaturedLinksReached={flyer.featuredLinkIds !== null && flyer.featuredLinkIds.length >= 5}
              setFeaturedLinkType={setFeaturedLinkType}
              setFeaturedLinkValue={setFeaturedLinkValue}
              featureNextLink={featureNextLink}
              replaceFeaturedLink={replaceFeaturedLink}
              removeFeaturedLink={removeFeaturedLink}
              addNewFeaturedLink={addNewFeaturedLink}
              onInvalid={setInvalidCtas}
              onEdit={setEditingCtas}
            />
          </div>
          <div className={styles.section}>
            <Distributions
              flyer={flyer}
              onDateChange={handleDateChange}
              onTargetDateChange={handleTargetDateChange}
            />
          </div>
        </>
        <FlyerPreview
          flyer={flyer}
          handleOcrUpdate={handleOcrUpdate}
          handleFlyerUpload={handleFlyerUpload}
          uploading={uploadingFlyer}
        />
      </TwoColumns>
      <Footer
        isValid={pageValid}
        updateFlyerDetails={updateFlyerDetails}
      />
    </DefaultPage>
  )
}

const styles = {
  section: css`
    margin-bottom: 55px;
  `,
}

const mapDispatchToProps = (dispatch) => ({
  onSuccess: (msg: string = DEFAULT_SUCCESS_NOTIFY_MSG) =>
    dispatch(notifySuccess(msg)),
  onError: (msg: string = DEFAULT_ERROR_NOTIFY_MSG) =>
    dispatch(notifyError(msg)),
})

export default connect(null, mapDispatchToProps)(withRouter(FlyerManager))

function convertBToMB(bytes) {
  return bytes / 1048576
}

async function uploadFlyerToS3(s3SignedUrl: string, file: any): Promise<any> {
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
        'x-amz-acl': 'public-read',
      },
    }

    return await axios.put(s3SignedUrl, file, options)
  } catch (e) {
    console.log('Error: unable to upload flyer to S3.')
    return null
  }
}