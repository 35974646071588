import apolloClient from './bffApolloClient'
import portalBffClient from './portalBffApolloClient'
import {
  GET_FLYER_DETAILS_QUERY,
  GET_PRESIGNED_PDF_URL,
  UPLOAD_FLYER_MUTATION,
  UPDATE_FLYER_DETAILS_MUTATIONS,
  ARE_SAFE_URLS_QUERY,
} from '../../FlyerManager/flyerManager.graphql'
import { get } from 'lodash'

type FlyerValidationError = {
  errorType: string
  errorMessages: string[]
}

export type SafeUrlResponse = {
  success: boolean
  safeUrls: {
    url: string
    isReachable: boolean
    isSafe: boolean
  } | null
}

export default class HttpClient {
  async fetchFlyerDetails(flyerId: string) {
    try {
      const response = await apolloClient.query({
        query: GET_FLYER_DETAILS_QUERY,
        variables: {
          legacyFlyerId: flyerId,
        },
      })

      const flyerDetails = get(response, 'data.getFlyerDetails', null)

      return {
        success: true,
        flyerDetails,
      }
    } catch (e) {
      console.log('Unable to fetch flyer details ', e)
      return {
        success: false,
      }
    }
  }

  async fetchPresignedPdfUrl(ext: string) {
    try {
      const response = await portalBffClient.query({
        query: GET_PRESIGNED_PDF_URL,
        fetchPolicy: 'no-cache',
        variables: {
          ext
        }
      })

      const presignedUrl = get(response, 'data.getPresignedPdfUrl')

      return {
        success: true,
        presignedUrl,
      }
    } catch (e) {
      console.log('Unable to fetch presigned pdf url.')
      return {
        success: false,
        presignedUrl: null,
      }
    }
  }

  async uploadFlyer(
    pdfUrl: string
  ): Promise<{
    success: boolean
    flyer: any
    validationErrors: FlyerValidationError[] | null
  }> {
    try {
      const response = await portalBffClient.mutate({
        mutation: UPLOAD_FLYER_MUTATION,
        variables: {
          pdfUrl,
        },
      })

      const { flyer, validationErrors } = get(response, 'data.uploadFlyer')

      return {
        success: true,
        flyer,
        validationErrors,
      }
    } catch (e) {
      console.log('Error: unable to uploader flyer')
      return {
        success: false,
        flyer: null,
        validationErrors: null,
      }
    }
  }

  async updateFlyerDetails(
    input: any
  ): Promise<{
    success: boolean
    flyerDetails: { campaignId: string; flyerId: string } | null
  }> {
    try {
      const response = await apolloClient.mutate({
        mutation: UPDATE_FLYER_DETAILS_MUTATIONS,
        variables: { input },
      })

      const { flyerId, campaignId } = get(response, 'data.updateFlyerDetails')

      return {
        success: true,
        flyerDetails: {
          flyerId,
          campaignId,
        },
      }
    } catch (e) {
      console.log('Error: unable to update flyer details')

      return {
        success: false,
        flyerDetails: null,
      }
    }
  }

  async checkUrlSafety(urls: string[]): Promise<SafeUrlResponse> {
    try {
      const response = await portalBffClient.query({
        query: ARE_SAFE_URLS_QUERY,
        variables: {
          urls,
        },
      })

      return {
        success: true,
        safeUrls: get(response, 'data.areSafeUrls'),
      }
    } catch (e) {
      return {
        success: false,
        safeUrls: null,
      }
    }
  }
}

export const httpClient = new HttpClient()
