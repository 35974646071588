import { get } from 'lodash'
import { addDays, subDays } from 'date-fns'
import utcDateToMidnightLocal from '../../utils/utcDateToMidnightLocal'

export const getPostExpirationDateRange = (
  distributions,
  campaignStart,
  today
) => {
  let minDate
  let maxDate

  const campStartDate = new Date(campaignStart)
  const localCampaignStart = utcDateToMidnightLocal(campStartDate)

  // Minus 1 to include today as today. This value is added below.
  const distributionInDays = distributions * 30 - 1
  const defaultDate = campaignStart
    ? addDays(localCampaignStart, distributionInDays)
    : addDays(today, distributionInDays)

  if (distributions === 1) {
    minDate = addDays(localCampaignStart, 1)
    maxDate = addDays(localCampaignStart, 29)
  } else {
    minDate = subDays(defaultDate, 29)
    maxDate = addDays(localCampaignStart, distributionInDays)
  }

  return { min: minDate, max: maxDate }
}

const indexOfEnd = (string, substring) => {
  const index = string.indexOf(substring)
  return index === -1 ? -1 : index + substring.length
}

export const getPdfUrlFromResponse = (response) => {
  const responseUrl = get(response, 'config.url', '')

  if (responseUrl.includes('?')) {
    return  responseUrl.split('?').shift()
  }

  throw new Error(`Invalid response from S3: ${responseUrl}`)
}
