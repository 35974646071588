import gql from 'graphql-tag'

export const GET_FLYER_DETAILS_QUERY = gql`
  query getMyFlyerDetails($legacyFlyerId: String) {
    getFlyerDetails(legacyFlyerId: $legacyFlyerId) {
      campaignId
      flyerId
      pdfUrl
      title
      categories
      endDate
      startDate
      distributions
      distributeStatus
      distributionsSchoolCount
      audienceId
      ownerId
      ownerName
      ownerType
      ownerHierarchyId
      ownerHierarchyType
      ownerHierarchyName
      flyerPages {
        jpegUrl
        ocrText
        userText
        pageNumber
      }
      numberOfPages
      eventLocation
      ctas {
        id
        type
        value
      }
      featuredLinkIds
    }
  }
`

export const UPLOAD_FLYER_MUTATION = gql`
  mutation uploadFlyer($pdfUrl: String!) {
    uploadFlyer(pdfUrl: $pdfUrl) {
      flyer {
        id
        pdfUrl
        flyerPages {
          jpegUrl
          ocrText
          userText
          pageNumber
        }
        numberOfPages
        createdBy
        createdAt
      }
      validationErrors {
        errorType
        errorMessages
      }
      validationWarnings {
        warningType
        warningMessages
      }
    }
  }
`

export const UPDATE_FLYER_DETAILS_MUTATIONS = gql`
  mutation updateFlyerDetails($input: FlyerDetailsInput) {
    updateFlyerDetails(input: $input) {
      campaignId
      flyerId
    }
  }
`

export const GET_PRESIGNED_PDF_URL = gql`
  query GetPresignedUrlQuery($ext: String) {
    getPresignedPdfUrl(
      input: {
        ext: $ext
      }
    )
  }
`

export const ARE_SAFE_URLS_QUERY = gql`
  query areSafeUrls($urls: [String]) {
    areSafeUrls(urls: $urls) {
      url
      isSafe
      isReachable
    }
  }
`
